
function NotFound() {
    return (
    <div className="pagetext">
        <h1>404</h1>
        <p>Page could not be found.</p>
    </div>
    );
}

export default NotFound;
