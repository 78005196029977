import LargeThing from "../utils/largething.js";

function Home() {
    return (
    <>
        {/* <h1>Home</h1> */}
        <LargeThing id="" />
    </>
    );
}

export default Home;
